// functionalities Check

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Grid,
  Modal,
  Table,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import html2canvas from "html2canvas";
import QRCode from "react-qr-code";
import { BASE_URL, DOMAIN, EccentricityDiagramInstruemntID } from "../../global";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import { Autocomplete } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HelpIcon from "@mui/icons-material/Help";
import UnitechHeader from "../image/certificate_header.png";
import UnitechHeaderNonNabl from "../image/certificate_header_nonnabl.png";
import UnitechHeaderIas from "../image/certificate_header_ias.png";
import UnitechFooter from "../image/certificate_footer.png";
import DraftImage from "../image/draft.png";
import ScanCopyImage from "../image/scancopy.png";
import LogoImage from "../image/WatermarkLogo.png";
import WithoutLogoImage from "../image/LogoWithoutletterhead.png";

import "./certificate.css";
import "./certificateList.css";
import RenderStaticTables from "./renderTables";
import { useGetRoleInfo } from "../login/Login";
import { makeChunks } from "../helper/helpers";
import { notifiy } from "../notification/Notification";
import { async } from "q";
import { countOfCertificatesAnalytics } from "../../utils/analytics/certificateAnalytics";
import { updateSRFAnalytics } from "../../utils/analytics/srfAnalytics";
import { viewCertificateGuide } from "./guide";
import { fetchAddresses } from "../invoice/productDetails";
import { allSettings } from "../../utils/components/allSettings";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";
import { ChangeCertificateStatus } from "./changeCertificateStatus";
import { accuracyFormat, parseRange } from "../../utils/components/accuracyandlcandrangesshow";
import { generatePDF } from "./printCertificate/printCertificate";

const TableCell = withStyles({
  root: {
    border: "none",
    padding: "0px",
    margin: "0px",
    lineHeight: "1.32",
    fontSize: "12px",
    paddingLeft: "5px",
  },
})(MuiTableCell);

const useStyles = makeStyles({
  table: {
    border: "1px solid black",
    padding: "1px",
  },
  infoCell: {
    maxHeight: "5px",
  },
  innerCell: {
    padding: "0px",
    maxWidth: "40%",
  },
  formatedCell: {
    fontSize: "0.82em",
    padding: "0px",
    margin: "0px",
  },
  certificateCell: {
    fontSize: "0.82em",
    padding: "0px",
    margin: "0px",
    width: "20%",
  },
  sxPad: {
    padding: "5px",
  },
  addressCell: {
    width: "200px",
  },
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid cadetblue",
  padding: "20px",
  textAlign: "center",
  borderRadius: "5px",
};
const checkboxOptions = ["✔", "NA"];

export default function ViewCertificate(props) {
  const viewCertificateGuides = viewCertificateGuide();
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const { certificateId: paramCertificateId } = useParams();
  let paramMultipleCertificateIdsArray=paramCertificateId?.split(",");
  const [finalCertificateId, setFinalCertificateId] = React.useState(paramMultipleCertificateIdsArray[0]);

  const certificateId = props.certificateIds || finalCertificateId;

  const [fetchCount, setFetchCount] = useState(0);
  const [downloadingCount, setDownloadingCount] = useState(0);
  const [instrument, setInstrument] = React.useState(null);
  const [configuration, setConfiguration] = React.useState({});
  const [clientName, setClientName] = React.useState("");
  const [clientID, setClientID] = React.useState(null);
  const [standardMasterArray, setStandardMasterArray] = React.useState([]);
  const [instrumentName, setInstrumentName] = React.useState("");
  const [requestedName, setRequestedName] = React.useState("");
  const [make, setMake] = React.useState("");
  const [calibrationProcedureNo, setCalibrationProcedureNo] =
    React.useState("");
  const [referenceStandards, setReferenceStandards] = React.useState("");
  const [lc, setLC] = React.useState("");
  const [dcNumber, setDcNumber] = React.useState("");
  const [ranges, setRanges] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [newAddressArray, setNewAddressArray] = React.useState([]);
  const [calibrationDate, setCalibrationDate] = React.useState(null);
  const [approvedDate, setApprovedDate] = React.useState(null);
  const [reviewedDate, setReviewedDate] = React.useState(null);
  const [signedDate, setSignedDate] = React.useState(null);
  const [witnessDate, setWitnessDate] = React.useState(null);
  const [dcDate, setDcDate] = React.useState(null);
  const [nextDueDate, setNextDueDate] = React.useState(null);
  const [receiptDate, setReceiptDate] = React.useState(null);
  const [poDate, setPoDate] = React.useState(null);
  const [dateOfIssue, setDateOfIssue] = React.useState("");
  const [calibrationFrequency, setCalibrationFrequency] = React.useState(null);

  const [calProcRefNo, setCalProcRefNo] = React.useState("");
  const [mfgNo, setMfgNo] = React.useState("");
  const [rangeDbData, setRangeData] = React.useState([]);
  // const [allRangeData, setRangeDataArray] = React.useState([]);
  const [specificValuesFromTable, setSpecificValuesFromTable] = React.useState(
    []
  );
  const [status, setStatus] = React.useState(-1);
  const [identificationNo, setIdentificationNo] = React.useState("");
  const [DUCID, setDUCID] = React.useState([]);
  const [calType, setCalType] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [accuracy, setAccuracy] = React.useState("");
  const [filterRangeData, setFilterRangeData] = React.useState([]);
  const [serialNo, setSerialNo] = React.useState("");
  const [selectedStandards, setSelectedStandards] = React.useState("");
  const [ulrNumber, setUlrNumber] = React.useState(null);
  const [certificateNumber, setCertificateNumber] = React.useState("");
  const [serviceReqNumber, setServiceReqNumber] = React.useState("");
  const [certificateServiceReqNumber, setCertificateServiceReqNumber] = React.useState("");
  const [jobNumber, setJobNumber] = React.useState("");
  const [workOrderNumber, setWorkOrderNumber] = React.useState("");
  const [poNo, setPoNo] = React.useState(null);
  const [startTemp, setStartTemp] = React.useState("");
  const [endTemp, setEndTemp] = React.useState("");
  const [startHumidity, setStartHumidity] = React.useState("");
  const [endHumidity, setEndHumidity] = React.useState("");
  const [models, setModel] = React.useState("");
  const [ConOfDuc, setConOfDuc] = React.useState("");
  const [calPoint, setCalPoint] = React.useState("");
  const [calMethod, setCalMethod] = React.useState("");
  const [locationOfInstrument, setLocationOfInstrument] = React.useState("");
  const [srnNo, setSrnNo] = React.useState("");
  const [atmosphericPressure, setAtmosphericPressure] = React.useState("");
  const [disciplineName, setDisciplineName] = React.useState("");
  const userType = localStorage.getItem("type");
  const [tableDisplayStatus, setTableDisplayStatus] = React.useState(1);
  const [settingList, setSettingList] = React.useState({});
  const [id, setId] = React.useState(-1);
  const [calibratedby, setCalibratedby] = React.useState("");
  const [approvedby, setApprovedby] = React.useState("");
  const [rejectedBy, setRejectedBy] = React.useState("");
  const [reviewedBy, setReviewedBy] = React.useState("");
  const [signedBy, setSignedBy] = React.useState("");
  const [witnessedBy, setWitnessedBy] = React.useState("");
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
  const [letterHead, setLetterHead] = React.useState(true);
  const [draft, setDraft] = React.useState(false);
  const [scanCopy, setScanCopy] = React.useState(false);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [letterHeadCompnayType, setLetterHeadCompnayType] = React.useState(true);
  const [extraColumns, setExtraColumns] = React.useState([]);
  const [departmet, setDepartment] = React.useState("");
  const [input, setInput] = React.useState("");
  const [operatingrange, setOperatingrand] = React.useState("");
  const [revisionNumber, setRevisionNumber] = React.useState("");
  const [amendmnetHistory, setAmendmentHistory] = React.useState("");
  const [calibrationReason, setCalibrationReason] = React.useState(1);
  const [certificateDownloadCount, setCertificateDownloadCount] =
    React.useState({});
  const [equipmentName, setEquipmentName] = React.useState("");
  const [equipmentId, setEquipmentId] = React.useState("");
  const [type, setType] = React.useState("");
  const [isClientSettings, setIsClientSettings] = React.useState(false);
  const [isCompanyHierarchy, setIsCompanyHierarchy] = React.useState(false);
  const [complianceStatus, setComplianceStatus] = React.useState(null);
  const [compliance, setCompliance] = React.useState(false);
  const [lastModified, setLastModified] = React.useState("");
  const [showAmendment, setShowAmendment] = React.useState(false);
  const [instrumentId, setInstrumentId] = React.useState("");
  const [deletedTableIds, setDeletedTableIds] = React.useState("");
  const [rolesSettins, setRolesSettings] = React.useState("");
  const [calTypeSetting, setCalTypeSetting] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const [pdfGenerated, setpdfGenerated] = React.useState(false);
  const [allocatedULRNo, setAllocatedULRNo] = useState(false);
  const [datasheetImages, setDatasheetImages] = useState("");
	  

  const [roles, setroles] = useState([]);
  const roles_ids = roles?.map((r) => Number(r.id));
  const [analysisSelectedOption, setAnalysisSelectedOption] = React.useState({
    accepted: "",
    calibrated: "",
    limitedUse: "",
    rejected: "",
  });
  const [downloadPdfloading, setDownloadPdfloading] = React.useState(false);
  const [organizationLables, setOrganizationLables] = useState([]);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [companyHierarchy, setCompanyHierarchy] = useState("");
  const [signImg, setSignImg] = React.useState(false);
  const [tableDivide, setTableDivide] = useState(false);
  const storedDeviceInfo = localStorage.getItem("deviceInfo");

  let footerSignSetting = {
    roles,
    calibratedby,
    approvedby,
    organizationLables,
    reviewedBy,
    clientName,
    signedBy,
    witnessedBy,
    signImg,
    instrument,
    newAddressArray
  };

  let letterHeadAndElectronicAndDraft = {
    letterHead,
    draft,
    signImg,
    electronicSign,
    scanCopy,
    letterHeadCompnayType
  };
  
  let remarksCondition = {
    settingList,
    configuration,
    complianceStatus,
    calTypeSetting,
    calibrationReason
  }

  let printProps = {
    certificateId,
    footerSignSetting,
    letterHeadAndElectronicAndDraft,
    remarksCondition,
    setDownloadPdfloading
  };

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  let designation = localStorage.getItem("designation");

  const convertToBooleanOrEmpty = (value) => {
    if (value === "1") return true;
    if (value === "") return "";
    return false;
  };
  
  const handleClose = () => {
    setModalOpen(false);
  };
  

  // api calls
  function fetchSettings() {
    axiosWithToken
      .get(BASE_URL + `settings?_where=(status,eq,1)`)
      .then((res) => {
        setFetchCount((prev)=> prev+1)
        let remark = null,
          complance = false,
          calTypeSetting = false,
          clientSetting = false,
          companyHierarchy = false,
          settings = res.data,
          _rolesSettings = {};
        let _settingList = {};
        settings.forEach((setting) => {
          if (setting?.keyName?.toLowerCase() === "remarks") {
            remark = setting?.value;
          } else if (
            setting?.keyName === "Compliance Status" &&
            setting?.value?.toLowerCase() === "true"
          ) {
            complance = true;
          } else if (
            setting?.keyName === "Client Settings" &&
            setting?.value?.toLowerCase() === "true"
          ) {
            clientSetting = true;
          } else if (
            setting?.keyName === "companyHierarchy" && 
            setting.status == 1
          ) {
            companyHierarchy = true;
          } else if (
            setting?.keyName === "Calibration Type" &&
            setting?.value?.toLowerCase() === "true"
          ) {
            calTypeSetting = true;
          }
          if (setting?.keyName?.startsWith("activeRole")) {
            _rolesSettings[setting?.keyName?.split("activeRole")[1]] = {
              name: setting?.value,
              id: setting?.keyName?.split("activeRole")[1],
            };
          }

          _settingList[setting?.keyName] = setting?.value;
        });
        _settingList["amendment"] = _settingList["amendment"]?.toLowerCase();
        // setSettingList(_settingList);
        setCompliance(complance);
        setIsClientSettings(clientSetting);
        setIsCompanyHierarchy(companyHierarchy);
        setCalTypeSetting(calTypeSetting);
        setRolesSettings(_rolesSettings);
      })
      .catch((err) => {
        console.error("Error fetching setting:", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "fetchSettings", "View Certificate")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }
  
  function downloadAuditlog(){
    let url = BASE_URL;
    let payload = {
      clientId: clientID,
      type: "certificates",
      identifier: certificateId,
      action: "download",
      userId: user[0],
      change_date: moment(new Date().toISOString()).format("YYYY-MM-DD HH:mm:ss")
    };
    axiosWithToken
      .post(url + `audit_log`, payload)
      .then((res) => res.data)
      .catch((err) => {
        console.log("certificate downloadAudit: ", err);
      });
  }

  function fetchCertificate() {
    let url = BASE_URL;
    let sqlQuery = {
      query: `select * from certificatesView where id = ${certificateId}`,
    };
    axiosWithToken
      .post(url + `dynamic`, sqlQuery)
      .then((res) => {
        // console.log(res.data);
        setFetchCount((prev)=> prev+1);
        if (res.data.length && res.data[0] != null) {
          setLastModified(res.data[0]?.lastModified);
          setStatus(res.data[0]?.status);
          setId(res.data[0]?.id);
          setClientName(res.data[0]?.companyName);
          setClientID(res.data[0]?.clientId);
          setInstrumentName(res.data[0]?.instrumentName);
          setMake(res.data[0]?.make);
          setModel(res.data[0]?.model);
          setLC(res.data[0]?.lc);
          setRanges(parseRange(res.data[0]?.ranges));
          setReferenceStandards(res.data[0]?.isaStandard ? res.data[0]?.isaStandard : res.data[0]?.referenceStandards);
          setTableDivide(res.data[0]?.tablePartition == 1 ? true : false);
          setSelectedStandards(res.data[0]?.additionalStandardIds?.split(","));
          setCalibrationProcedureNo(res.data[0]?.calibrationProcedureNo);
          setAddress(res.data[0]?.billingAddress ? res.data[0]?.billingAddress : res.data[0]?.address);
          setDcNumber(res.data[0]?.dcNumber);
          setUlrNumber(res.data[0]?.ULRNo);
          setAllocatedULRNo(res.data[0]?.allocatedULRNo);
          setCertificateNumber(res.data[0]?.certificateNumber);
          setDcDate(res.data[0]?.dcDate);
          setNextDueDate(res.data[0]?.nextDueDate);
          setReceiptDate(res.data[0]?.receiptDate);
          setCalProcRefNo(res.data[0]?.calProcRefNo);
          setMfgNo(res.data[0]?.mfgNo);
          setIdentificationNo(res.data[0]?.identificationNo);
          setDUCID(res.data[0]?.DUCID);
          setCalType(res.data[0]?.calibrationType);
          setLocation(res.data[0]?.location == 1 ? "In House" :"Onsite");
          setAccuracy(res.data[0]?.accuracy);
          setSerialNo(res.data[0]?.serialNumber);
          setServiceReqNumber(res.data[0]?.serviceReqNumber);
          setCertificateServiceReqNumber(res.data[0]?.certificatesserviceReqNumber);
          setJobNumber(res.data[0]?.jobNumber);
          setWorkOrderNumber(res.data[0]?.workOrderNumber);
          setPoNo(res.data[0]?.poNumber);
          setPoDate(res.data[0]?.poDate);
          setStartTemp(res.data[0]?.startTemp);
          setEndTemp(res.data[0]?.endTemp);
          setStartHumidity(res.data[0]?.startHumidity);
          setEndHumidity(res.data[0]?.endHumidity);
          setDateOfIssue(res.data[0]?.dateOfIssue);
          setModel(res.data[0]?.model);
          setConOfDuc(res.data[0]?.ConOfDuc);
          setCalPoint(res.data[0]?.calPoint);
          setCalMethod(res.data[0]?.calMethod);
          setLocationOfInstrument(res.data[0]?.locationOfInstrument);
          setSrnNo(res.data[0]?.srnNo);
          setAtmosphericPressure(res.data[0]?.atmosphericPressure);
          setConfiguration(JSON.parse(res.data[0]?.configuration));
          setDisciplineName(res.data[0]?.name);
          setOperatingrand(parseRange(res.data[0]?.operatingRange));
          setDepartment(res.data[0]?.department);
          let extraEvvtries = res.data[0].extraColumns
            ? JSON.parse(res.data[0].extraColumns)
            : {};
          setInput(extraEvvtries["input"]);
          setEquipmentName(extraEvvtries["Equipment Name"]);
          setEquipmentId(extraEvvtries["Equipment ID"]);
          setType(extraEvvtries["Type"]);
          setExtraColumns(
            Object.keys(extraEvvtries).length
              ? Object.entries(extraEvvtries)
              : []
          );
          setCalibrationReason(res.data[0].calibrationReason);
          setInstrumentId(res.data[0]?.instrumentId);
          setDeletedTableIds(res.data[0]?.deletedTableIds);
          res.data[0]?.specificValues
            ? setSpecificValuesFromTable(res.data[0]?.specificValues.split(","))
            : setSpecificValuesFromTable([]);
          setTableDisplayStatus(res.data[0]?.tableDisplayStatus);
          setCompanyHierarchy(res.data[0]?.companyHierarchy);
          setDatasheetImages(res.data[0]?.datasheetImages);

          axiosWithToken
            .get(
              BASE_URL +
                `users?_where=(id,in${
                  res.data[0]?.calibratedby
                    ? `,${res.data[0]?.calibratedby}`
                    : "" || ""
                }${
                  res.data[0]?.approvedby
                    ? `,${res.data[0]?.approvedby}`
                    : "" || ""
                }${
                  res.data[0]?.rejectedBy
                    ? `,${res.data[0]?.rejectedBy}`
                    : "" || ""
                }${
                  res.data[0]?.reviewedBy
                    ? `,${res.data[0]?.reviewedBy}`
                    : "" || ""
                }${
                  res.data[0]?.signedBy ? `,${res.data[0]?.signedBy}` : "" || ""
                }${
                  res.data[0]?.witnessBy
                    ? `,${res.data[0]?.witnessBy}`
                    : "" || ""
                })`
            )
            .then((res2) => {
              setFetchCount((prev)=> prev+1);
              // Logic to remove _c and _u if xjoin if used
              // Update replace all if xjoin members are increased in future
              // let tmp = [...res.data];
              // tmp.map((e, i) =>
              //   Object.keys(e).map(
              //     (k) =>
              //       (res.data[i][k.replaceAll("_c", "").replaceAll("_u", "")] =
              //         e[k])
              //   )
              // );
              res2.data.map((e) => {
                if (e.id == res.data[0]?.calibratedby) {
                  setCalibratedby([
                    res.data[0]?.calibratedby,
                    e.userName,
                    e.id,
                    e.companyName,
                    res.data[0]?.calibrateddesignation,
                    e?.usersign,
                  ]);
                }
                if (e.id == res.data[0]?.approvedby)
                  setApprovedby([
                    res.data[0]?.approvedby,
                    e.userName,
                    e.id,
                    e.companyName,
                    res.data[0]?.approveddesignation,
                    e?.usersign,
                  ]);
                if (e.id == res.data[0]?.rejectedBy)
                  setRejectedBy([
                    res.data[0]?.rejectedBy,
                    e.userName,
                    e.id,
                    e.companyName,
                  ]);
                if (e.id == res.data[0]?.reviewedBy)
                  setReviewedBy([
                    res.data[0]?.reviewedBy,
                    e.userName,
                    e.id,
                    e.companyName,
                    e?.usersign,
                  ]);
                if (e.id == res.data[0]?.signedBy)
                  setSignedBy([
                    res.data[0]?.signedBy,
                    e.userName,
                    e.id,
                    e.companyName,
                    e?.usersign,
                  ]);
                if (e.id == res.data[0]?.witnessBy)
                  setWitnessedBy([
                    res.data[0]?.witnessBy,
                    e.userName,
                    e.id,
                    e.companyName,
                    e?.usersign,
                  ]);
              });
            });

          setCalibrationDate(res.data[0].calibrationDate);
          setApprovedDate(res.data[0]?.approvedDate);
          setReviewedDate(res.data[0]?.reviewedDate);
          setSignedDate(res.data[0]?.signedDate);
          setWitnessDate(res.data[0]?.witnessDate);

          setCalibrationFrequency(res.data[0]?.calFrequency == "Not Required" ? "-" : res.data[0]?.calFrequency);
          setRevisionNumber(res.data[0]?.revisionNumber);
          let tmpSplt = res.data[0]?.amendment
            ? res.data[0]?.amendment.split("\n")
            : [];
          setAmendmentHistory(
            tmpSplt.filter((_, i) => i === tmpSplt.length - 1)
          );
          setRequestedName(
            res.data[0]?.requestedname ? res.data[0]?.requestedname : ""
          );
          setComplianceStatus(res.data[0]?.complianceStatus);

          fetchInstrument(res.data[0]?.instrumentId);

          axiosWithToken
            .get(
              url +
                `datasheetStaticReadings?_where=(datasheetId,eq,${certificateId})`
            )
            .then((response) => {
              setFetchCount((prev)=> prev+1);
              if (response?.data) {
                let standardIds = response?.data
                  ?.map((e) => e.standardRanges)
                  .filter(Boolean);
                const selectedStandardIds = [];
                standardIds.forEach((str) => {
                  str.split(",").forEach((sub) => {
                    const num = parseInt(sub.split(":")[0]);
                    if (!selectedStandardIds.includes(num)) {
                      selectedStandardIds.push(num);
                    }
                  });
                });

                if (selectedStandardIds) {
                  let combineArray = [...new Set(selectedStandardIds)];
                  axiosWithToken
                    .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
                    .then((res) => {
                      setStandardMasterArray(res.data)
                      setFetchCount((prev)=> prev+1)
                    })
                    .catch((err) => console.log(err));
                }
                setFetchCount((prev)=> prev+1)
              }
            })
            .catch((err) => console.log(err));

            if(res.data[0]?.billingId){
              axiosWithToken
              .get(BASE_URL + `clients/${res.data[0]?.billingId}`)
              .then((res) => {
                let billingName= res.data[0]?.companyName;
                setClientName(billingName ? billingName : res.data[0]?.companyName);
              })
              .catch((err) => console.log(err));
            }

        }
      })
      .catch((err) => {
        console.log("certificate data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(
                  err.message,
                  "fetchCertificate",
                  "View Certificate"
                )
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  function fetchInstrument(instrumentId) {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `instruments?_where=(id,eq,${instrumentId})`)
      .then((res) => {
        setFetchCount((prev)=> prev+1);
        setInstrument(res.data);
      })
      .catch((err) => {
        console.log("certificate tables data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "fetchInstrument", "View Certificate")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  function certificateCount() {
    let data = {
      query: `SELECT * FROM certificateAnalytics WHERE certificateId = ${certificateId} `,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        // setFetchCount((prev)=> prev+1)
        setCertificateDownloadCount(res.data[0]);
      })
      .catch((err) => {
        console.log("certificateAnalytics tables data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(
                  err.message,
                  "certificateCount",
                  "View Certificate"
                )
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }


  useEffect(() => {
    const fetchClientSetting = async () => {
      try {
        const AllSettings = await allSettings(
          isClientSettings ? clientID : undefined,
          (isClientSettings && isCompanyHierarchy) ? companyHierarchy : undefined
        );
        let _settingList = {};
        AllSettings.forEach((setting) => {
          _settingList[setting?.keyName] = setting.value;
        });
        setSettingList(_settingList);

        const footerRoles = AllSettings?.filter(
          (el) =>
            el.keyName?.includes("role") &&
            el.value !== "false" &&
            !el.keyName?.includes("_PossibleFields")
        )
          ?.map((item) => ({
            name: item.value,
            id: item.keyName.charAt(item.keyName.length - 1),
            label: item.value,
            key: item.keyName,
          }))
          ?.sort((a, b) => a.id - b.id);

        let OrgLables = footerRoles?.filter((el) => el.key.includes("Label"));
        let footerRoleFilter = footerRoles?.filter(
          (el) => !el.key.includes("Label")
        );

        setOrganizationLables(OrgLables);
        setroles(footerRoleFilter);
        setFetchCount((prev)=> prev+1);
      } catch (err) {
        console.error("Error fetching data:", err);
        let trace = new Error().stack;
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, trace, "View Certificate")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      }
    };

    fetchClientSetting();
  }, [clientID, isClientSettings, companyHierarchy, isCompanyHierarchy, certificateNumber]);

  function getOpinionAndAnalysisList() {
    let url = BASE_URL;
    const payload = {
      query: `SELECT * FROM opinionAndAnalysis WHERE certificateId = ${certificateId}`,
    };
    axiosWithToken
      .post(url + "dynamic", payload)
      .then((res) => {
        if (res.data.length > 0) {
          setFetchCount((prev)=> prev+1);
          const parsedData = {
            ...res.data[0],
            accepted: convertToBooleanOrEmpty(res.data[0].accepted),
            limitedUse: convertToBooleanOrEmpty(res.data[0].limitedUse),
            calibrated: convertToBooleanOrEmpty(res.data[0].calibrated),
            rejected: convertToBooleanOrEmpty(res.data[0].rejected),
          };
          setAnalysisSelectedOption(parsedData);
        }
      })
      .catch((err) => {
        console.log("Something Went Wrong!");
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(
                  err.message,
                  "getOpinionAndAnalysisList",
                  "View Certificate"
                )
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  useEffect(() => {
    fetchCertificate();
    fetchSettings();
    certificateCount();
    getOpinionAndAnalysisList();
  }, [finalCertificateId]);


  useEffect(() => {


    if (fetchCount > 6 && pdfGenerated && paramMultipleCertificateIdsArray.length > 1 && downloadingCount < paramMultipleCertificateIdsArray.length) {
     const timer = setTimeout(() => {
        generatePDF(printProps).then(() => {
          setFetchCount(0);
         if (downloadingCount+1 < paramMultipleCertificateIdsArray.length) {
            setFinalCertificateId(paramMultipleCertificateIdsArray[downloadingCount+1]);
          }
          setDownloadingCount((prev) => prev + 1);
          // pdfGenerated = false;
        });
      }, 1000);
      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [certificateNumber, fetchCount, pdfGenerated]);


  useEffect(() => {
if(!paramMultipleCertificateIdsArray.length > 1){
    if (userType == 3 && approvedby.length == 0) {
      setDraft(true);
      setLetterHead(false);
    } else {
      setDraft(false);
      setLetterHead(true);
    }
  }
  }, [userType, approvedby]);


  var refresh = () => {
    window.location.reload(false);
  };

  const generateScanCopyImage = async () => {
    let htmlString = `
    <div>
    <img src=${ScanCopyImage} style="width:100%; height:1200px; opacity:0.4"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, scale: 1 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateDynamicHeader = async (
    ulrNumber,
    certificateNumber,
    pageNo,
    totalPages
  ) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
        ${calType == 1 ? `
          <td style="padding:5px;font-family: Calibri, sans-serif;height:30px;">
            <b style="font-size:20px;">ULR No</b>
          </td>`: ""}
          <td style="padding:5px;font-family: Calibri, sans-serif;height:30px;">
            <b style="font-size:20px;">Date of Calibration</b>
          </td>
          <td style="padding:5px;font-family: Calibri, sans-serif;height:30px;">
            <b style="font-size:20px;">Next Calibration Due</b>
          </td>
          <td style="padding:5px;font-family: Calibri, sans-serif;height:30px;">
            <b style="font-size:20px;">Page Number</b>
          </td>
        </tr>
        <tr>
        ${calType == 1 ? `
          <td style="padding:5px;font-family: Calibri, sans-serif;height:30px;">
              <b style="font-size:19px;">${ulrNumber}</b>
            </td>  
          ` : ""}
        <td style="padding:5px;font-family: Calibri, sans-serif;height:30px;">
            <b style="font-size:19px;">${formatDateByClient(calibrationDate)}</b>
          </td>
          <td style="padding:5px;font-family: Calibri, sans-serif;height:30px;">
            <b style="font-size:19px;">${calibrationFrequency == "-" ? "-": formatDateByClient(nextDueDate)}</b>
          </td>
          <td style="padding:5px;font-family: Calibri, sans-serif;height:30px;">
            <b style="font-size:19px;">${pageRange}</b>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, pixelRatio: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${
        calType == 1
          ? UnitechHeader
          : calType == 2
          ? UnitechHeaderIas
          : UnitechHeaderNonNabl
      } style="width:100%; height:170px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };
  
  const generateDraftImage = async () => {
    let htmlString = `
    <div>
        ${
          letterHead
            ? `<img src=${LogoImage} style="width:70%; height:600px; opacity:0.3"/>`
            : `<img src=${WithoutLogoImage} style="width:70%; height:1px; opacity:0.3;"/>`
        }
        ${
          draft
            ? `<img src=${DraftImage} style="width:100%; height:1200px; opacity:0.4"/>`
            : ""
        }
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, scale: 1 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateDynamicFooter = async (
    calibratedby,
    approvedby,
    calibrationDate
  ) => {
    let footers = [...roles];
    footers.map((e, i) => {
      if (e.id == 1) {
        footers[i].imgSrc = calibratedby[5];
        footers[i].by = calibratedby[1];
        footers[i].date = calibrationDate;
        footers[i].companyName = calibratedby[3];
        footers[i].label = organizationLables?.[0]
          ? organizationLables[0]?.name
          : "Calibrated By";
        footers[i].name = calibratedby[4] ? calibratedby[4] : footers[i].name;
        } else if (e.id == 2) {
        footers[i].imgSrc = approvedby[5];
        footers[i].by = approvedby[1];
        footers[i].date = approvedDate;
        footers[i].companyName = approvedby[3];
        footers[i].label = organizationLables?.[1]
          ? organizationLables[1]?.name
          : "Checked And Approved By";
        footers[i].name = approvedby[4] ? approvedby[4] : footers[i].name;
        } else if (e.id == 3) {
        footers[i].imgSrc = reviewedBy[4];  
        footers[i].by = reviewedBy[1];
        footers[i].date = reviewedDate;
        footers[i].companyName = reviewedBy[3];
        footers[i].name = clientName;
      } else if (e.id == 4) {
        footers[i].imgSrc = signedBy[4];
        footers[i].by = signedBy[1];
        footers[i].date = signedDate;
        footers[i].companyName = signedBy[3];
        footers[i].name = clientName;
      } else if (e.id == 5) {
        footers[i].imgSrc = witnessedBy[4];
        footers[i].by = witnessedBy[1];
        footers[i].date = witnessDate;
        footers[i].companyName = witnessedBy[3];
        footers[i].name = clientName;
      }
    });
    if (footers.length <= 4) {
      footers = makeChunks(footers, footers.length == 4 ? 4 : 3);
    } else if (footers.length == 5) {
      footers = [
        [footers[0], footers[1]],
        [footers[2], footers[3], footers[4]],
      ];
    }
    footers.map((row, i) => {
      footers[i][0].align = "center";
      if (row.length === 3) {
        footers[i][1].align = "center";
      }
      footers[i][row.length - 1].align = "center";
    });
    let footerString = `
    <div id="responsivepdfheader">
      ${footers
        .map(
          (row, index) => `
          <table style="width:100%;margin-bottom:5px">
          ${
            index === 0 && footers.length !== 2
              ? `<tr>
              ${
                row.some((item) => item.id === "1" || item.id === "2")
                  ? `<td colspan="${
                      row.filter((item) => item.id === "1" || item.id === "2")
                        .length
                    }">
                    <b style="font-size:21px;">
                      <b style="text-transform: 'uppercase'">For Vaibhav Instrumentation Pvt.Ltd.</b> 
                    </b>
                </td>`
                  : ""
              }
              ${
                row.some(
                  (item) =>
                    item.id === "3" || item.id === "4" || item.id === "5"
                )
                  ? `<td colspan="${
                      row.filter(
                        (item) =>
                          item.id === "3" || item.id === "4" || item.id === "5"
                      ).length
                    }">
                    <b style="font-size:21px;">
                        <b style="text-transform: 'uppercase'">For Customer</b> 
                    </b>
                </td>`
                  : ""
              }
            </tr>`
              : ""
          }
          <tr>
            ${row
              .map((column) =>
                electronicSign
                  ? `
                  <td style="vertical-align:top;text-align: ${
                    column.align
                  };font-family: Calibri, sans-serif;line-height:30px;width:${
                      100 / row.length
                    }%">
                    <div style="width: 100%;disply:flex;flex-direction:column;">
                    <div style="border-bottom:1px solid black;padding:5px 15px;">
                    <b style="font-size:20px;">
                    <b style="text-transform: 'uppercase'">${column.label}</b> 
                    </b>
                    </div>
                    <div style="border-bottom:1px solid black;padding:5px 15px;">
                    <b style="font-size:20px;">
                    ${column.by ? "Electronically signed by" : "\u00A0"}
                        </b>
                        <br>
                          <b style="font-size:19px;">${
                            column.by ? column.by : "\u00A0"
                          }
                            <br>
                            ${
                              column.date
                                ? formatDateByClient(column.date,true)
                                : "\u00A0"
                            }
                        </b>
                    </div>
                    <div style="padding:5px 15px;">
                    <b style="font-size:20px;">
                    <b style="text-transform: 'uppercase'">${column.name}</b> 
                    </b>
                    </div>
                    </div>    
                  </td>`
                  : `
                  <td style="vertical-align:top;text-align: ${
                    column.align
                  };font-family: Calibri, sans-serif;line-height:30px;width:${
                      100 / row.length
                    }%">
                    <div style="width: 100%;disply:flex;flex-direction:column;">
                    <div style="border-bottom:1px solid black;padding:5px 15px;">
                    <b style="font-size:20px;">
                    <b style="text-transform: 'uppercase'">${column.label}</b> 
                    </b>
                    </div>
                    <div style="border-bottom: ${signImg ? 'none' : '1px solid black'}; padding: 5px 15px;height:190px;">                   
                   </div>
                   ${signImg ? 
                    `<div style="border-bottom:1px solid black;padding:5px 15px;height:80px;">
                      ${column?.imgSrc ? `<img src="${column?.imgSrc}" style="width:30%; height:100%;border:none;"/>` : ''}
                     </div>`
                    : ''}                  
                    <div style="padding:5px 15px;">
                    <b style="font-size:20px;">
                    <b style="text-transform: 'uppercase'">${column.name}</b> 
                    </b>
                    </div>
                    </div>    
                  </td>`
              )
              .join("")}
          </tr></table>`
        )
        .join("")}
    </div>`;

    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = footerString;
    let image = await toPng(htmlNode, { quality: 1, pixelRatio: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return [img, footers.length];
  };
  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };
  var refresh = () => {
    window.location.reload(false);
  };
  // const generatePDF = async () => {
  //   setDownloadPdfloading(true);
  //   try {
  //     const element = printComponentRef.current;
  //     const [_, marginNumber] = await generateDynamicFooter(
  //       calibratedby,
  //       approvedby,
  //       calibrationDate
  //     );
  //     let footerHeight = 170;
  //     let footerPos = [0, 650];
  //     let pdf = await html2pdf()
  //       .from(element)
  //       .set({
  //         margin: [157, 1, 17 + marginNumber * footerHeight, 1],
  //         filename: `certificate_${certificateId}.pdf`,
  //         pagebreak: { after: "section", mode: ["css", "legacy"] },
  //         html2canvas: {
  //           dpi: 192,
  //           scale: 2.5,
  //           letterRendering: true,
  //           useCORS: true,
  //         },
  //         image: { type: 'png', quality: 0.4 },
  //         jsPDF: {
  //           orientation: "portrait",
  //           unit: "pt",
  //           format: "a4",
  //           compress: true, 
  //         },
  //       })
  //       .toPdf()
  //       .get("pdf");

  //     const totalPages = pdf.internal.getNumberOfPages();
  //     // Load the watermark image
  //     const watermarkImage =scanCopy ? await generateScanCopyImage() : await generateDraftImage();

  //     for (let i = 1; i <= totalPages; i++) {
  //       pdf.setPage(i);
  //       pdf.setFontSize(9);
  //       pdf.setTextColor(0, 0, 0);
  //       pdf.setFont("helvetica", "bold");

  //       // Add watermark image at the back of the page
  //       const pageWidth1 = pdf.internal.pageSize.getWidth();
  //       const pageHeight = pdf.internal.pageSize.getHeight();
  //       const imgWidth = 400;
  //       const imgHeight =
  //         (imgWidth * watermarkImage.height) / watermarkImage.width;
  //       const x1 = (pageWidth1 - imgWidth) / 2;
  //       const y1 = (pageHeight - imgHeight) / 2;
  //       pdf.addImage(watermarkImage.src, "PNG", x1, y1, imgWidth, imgHeight);

  //       // border add for contnet
  //       const borderWidth = 0.6;
  //       const leftMargin = 15;
  //       const borderX = leftMargin;
  //       const borderY = 155;
  //       const rightMargin = 595 - leftMargin * 0.95;
  //       pdf.setLineWidth(borderWidth);
  //       pdf.rect(borderX, borderY, rightMargin - leftMargin, 505);

  //       let image = await generateDynamicHeader(
  //         ulrNumber,
  //         `${certificateNumber}${
  //           settingList["amendment"] === "true" && revisionNumber > 0
  //             ? `/AMD-${revisionNumber}`
  //             : ""
  //         }`,
  //         i,
  //         totalPages
  //       );
  //       pdf.addImage(image, 0, 108, 595, 55);
  //       let height;
  //       [image, height] = await generateDynamicFooter(
  //         calibratedby,
  //         approvedby,
  //         calibrationDate
  //       );

  //       pdf.addImage(
  //         image,
  //         footerPos[0],
  //         height > 1
  //           ? footerPos[1] - (height - 1) * footerHeight
  //           : footerPos[1],
  //         595,
  //         height * footerHeight
  //       );

  //       const text = "CALIBRATION CERTIFICATE";
  //       const pageWidth = pdf.internal.pageSize.getWidth();
  //       const textWidth =
  //         (pdf.getStringUnitWidth(text) * 12) / pdf.internal.scaleFactor;
  //       const x = (pageWidth - textWidth) / 2;
  //       pdf.setFontSize(12);
  //       pdf.text(text, x, 115);
  //       pdf.setFont("helvetica", "normal");

  //       if (letterHead) {
  //         let image = await generateLetterHeadHeader();

  //         pdf.addImage(image, 0, 0, 595, 95);

  //         // image = await generateLetterHeadFooter();
  //         // pdf.addImage(image, 0, 790, 595, 50);
  //       }
  //       if (
  //         electronicSign &&
  //         settingList["electronicSign"] &&
  //         settingList["electronicSign"].toLowerCase() === "true"
  //       ) {
  //         pdf.setFontSize(9);
  //         const text =
  //           "This certificate is electronically signed and does not required physical signature";
  //         const textWidth =
  //           (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
  //           pdf.internal.scaleFactor;
  //         const pageWidth = pdf.internal.pageSize.getWidth();
  //         const x = (pageWidth - textWidth) / 2;
  //         pdf.text(text, x, 803);
  //       }
  //       if (i === totalPages) {
  //         pdf.setFontSize(9);
  //         const text = "--------- END OF CALIBRATION CERTIFICATE ---------";
  //         const text1 =
  //           certificateDownloadCount?.certificateDownloadCounter > 0
  //             ? `Duplicate copy.${certificateDownloadCount.certificateDownloadCounter}`
  //             : ".";

  //         const textWidth =
  //           (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
  //           pdf.internal.scaleFactor;
  //         const pageWidth = pdf.internal.pageSize.getWidth();
  //         const x = (pageWidth - textWidth) / 2;
  //         pdf.text(text, x, 815);
  //         {
  //           settingList["Duplicate copy"] &&
  //             settingList["Duplicate copy"].toLowerCase() === "true" &&
  //             pdf.text(text1, pageWidth - 83, 815);
  //         }
  //       }
  //     }
  //     const blob = pdf.output("blob");
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = `${certificateNumber}.pdf`;
  //     a.click();
  //     // pdf.save();
  //     countOfCertificatesAnalytics([certificateId]);
  //     if (
  //       props.isPrint != true &&
  //       settingList["Duplicate copy"] &&
  //       settingList["Duplicate copy"].toLowerCase() === "true"
  //     ) {
  //       setTimeout(() => {
  //         refresh();
  //       }, 500);
  //     }
  //     setDownloadPdfloading(false);
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //     setDownloadPdfloading(false);
  //   }
  // };

  const formatDateByClient = (date, time) => {
    if (!date || !moment(date).isValid()) {
      return "\u00A0"; // Return non-breaking space if date is invalid or empty
    }
    const dateFormat =
      settingList["Date format(word)"] &&
      settingList["Date format(word)"].toString().toLowerCase() === "true"
        ? "DD MMM YYYY"
        : "DD/MM/YYYY";

    if (time) {
      return moment(date).format(`${dateFormat}, HH:mm`);
    } else {
      return moment(date).format(dateFormat);
    }
  };  

  useEffect(async () => {
    let splitAddress = address?.split(",");
    let newAddressArray = await fetchAddresses(splitAddress?.[0], "0");
    setNewAddressArray(newAddressArray);
  }, [address]);

  useEffect(()=>{
    if(props.isPrint == true){
      setLetterHead(false);
      setElectronicSign(false);
      setSignImg(false);
      setDraft(true);
    }
  },[props.isPrint])

  const printView = () => {
    return (
      // <>
      <div
        id="printOnly2"
        // style={{ pageBreakAfter: "always" }}
        ref={printComponentRef}
      >
        <table style={{ width: "100%" }}>
          <tbody className="page" >
            <tr>
              <td style={{ border: "none" }}>
                <div
                  className="content"
                  style={{
                    fontSize: "11px",
                    margin: "20px",
                    marginTop: "0px",
                    marginBottom: "0px",
                    padding: "2px 10px",
                    // border: "1px solid black",
                  }}
                >
                  <Table>
                    {customerDetailsTable()}
                    <Table
                      className={classes.table}
                      style={{ marginBottom: "3px" }}
                    >
                      <TableRow>
                        <TableCell>
                          <b style={{textTransform:"uppercase"}}>Environment Condition</b>
                        </TableCell>
                        <TableCell>
                          <span>
                            <b style={{ paddingRight: "10px" }}>
                             Ambient Temperature :
                            </b>
                            {startTemp?.includes("°C")
                              ? startTemp?.replace("#", " ")
                              : startTemp + " °C"}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span>
                            <b style={{ paddingRight: "10px" }}>Relative Humidity:</b>
                            {startHumidity?.includes("%RH")
                              ? startHumidity?.replace("#", " ")
                              : startHumidity + "  %RH"}
                          </span>
                        </TableCell>
                      </TableRow>
                    </Table>
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <b>DESCRIPTION OF ITEM UNDER CALIBRATION</b>
                      </TableCell>
                    </TableRow>
                    {unitCalibrationDetails()}
                    {masterDataTable()}
                  </Table>
                  <div>
                    <div style={{ textAlign: "left" }}>
                      {/* <div style={{ marginBottom: "5px", marginTop: "10px" }}>
                        <b>DISCIPLINE : </b>
                        {disciplineName}
                      </div> */}
                      <div className="row">
                        <RenderStaticTables
                          tableDisplayStatus={tableDisplayStatus}
                          configuration={configuration}
                          certificateId={certificateId}
                          instrumentId={instrumentId}
                          deletedTableIds={deletedTableIds}
                          tableDivide={tableDivide}
                        />
                      </div>
                    </div>
                  </div>
                  {settingList?.["Opinion And Analysis Table"] === "true" &&
                    opinionAndAnalysis()}
                  <div className="row">
                    <div
                      className="col-12"
                      style={{ pageBreakInside: "avoid" }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            flex: "1",
                          }}
                        >
                          <div className="text-start" style={{}}>
                            <pre
                              style={{
                                fontFamily: " Calibri, sans-serif",
                                fontSize: "10px",
                                marginBottom: "0px",
                                padding: "0",
                                lineHeight: 1,
                                whiteSpace: "pre-wrap",
                                pageBreakInside: "avoid",
                              }}
                            >
                              <div
                                style={{
                                  border: "1px solid black",
                                  padding: "5px",
                                  marginTop: "1px",
                                }}
                              >
                                <b
                                  style={{ float: "left", fontSize: "11px" }}
                                  className="standardUnitTable"
                                >
                                  Remarks :
                                </b>
                                {settingList["Compliance Status"] &&
                                settingList[
                                  "Compliance Status"
                                ].toLowerCase() === "true"
                                  ? `Calibration ${
                                      complianceStatus == 1 ? "" : "doesn't"
                                    } Found Ok, Within Acceptance Criteria Limit.\n`
                                  : ""}
                                {calTypeSetting
                                  ? `Reason For Calibration : ${
                                      (
                                        settingList?.[
                                          "CalibrationTypeOptions"
                                        ]?.split(",") || []
                                      )
                                        .map((label, index) =>
                                          index === calibrationReason - 1
                                            ? label
                                            : null
                                        )
                                        .filter(
                                          (label) => label !== null
                                        )?.[0] || ""
                                    }\n`
                                  : ""}
                                {`${
                                  configuration?.remark || ""
                                }\n`}
                                {settingList["amendment"] === "true"
                                  ? (
                                    <>
                                      <div style={{marginTop:"4px"}}>
                                      Amendment Certificate No: <b>{certificateNumber}{revisionNumber > 0 ? `/AMD-${revisionNumber}.` : ""}</b>
                                      </div>
                                      <div>
                                     * This is the Amendment certificate of the <b>{certificateNumber}</b> dated <b>{formatDateByClient(calibrationDate)}</b>, issued on <b>{dateOfIssue ? formatDateByClient(dateOfIssue) : formatDateByClient(approvedDate)}</b>.
                                      </div>
                                      <div>
                                      * Reason for Amendment: 
                                      </div>
                                      &nbsp; {amendmnetHistory}
                                    </>
                                  )
                                  : ""}
                                  <b
                                  style={{ float: "left", fontSize: "11px" }}
                                  className="standardUnitTable"
                                >
                                  Note :
                                </b>
                                <span>
                                  1) UUC stands for Unit Under Calibration.<br/>
                                  2) This Certificate refers only to the particular item submitted for calibration.<br/>
                                  3) This certificate shall not be produced, except in full unless written permission for the publication of an approved abstract has been obtained from the Technical Manager of "Vaibhav Instrumentation, Pvt .Ltd .Nashik".<br/>
                                  4) The calibration results reported in the certificate are valid at the time of and under the stated conditions of measurement
                                  </span>
                              </div>
                            </pre>
                          </div>
                        </div>
                        {instrumentId == EccentricityDiagramInstruemntID && datasheetImages?.split(",")?.[0] && (
                        <div style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "stretch",
                          padding: "0px 5px",
                          maxHeight: "115px", 
                          width:"150px",
                          flexDirection:"column"
                        }}>
                          <b>Pan Position:</b>
                          <img src={datasheetImages?.split(",")?.[0] || ""} alt="weighttrect"  style={{ height: "90%", width:"100%" }}  />
                        </div>
                        )}
                        {/* <div
                          style={{
                            textAlignLast: "center",
                            padding: "0px 10px",
                            marginTop: "5px",
                          }}
                        >
                          <QRCode
                            value={`${DOMAIN}/#/certificate/print/${certificateId}`}
                            align="center"
                            size={60}
                          />
                        </div> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {settingList["lastModified"] &&
                        settingList["lastModified"].toLowerCase() === "true"
                          ? `\nPrint At : ${formatDateByClient(new Date(), true)}`
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ width: "100%" }} id="header-section"></div>
      </div>
      // </>
    );
    function customerDetailsTable() {
      return (
        <Table className={classes.table} style={{ marginBottom: "3px" }}>
          <TableRow className={classes.row}>
            <TableCell className={classes.addressCell} style={{ width: "50%", padding:"5px", verticalAlign:"top" }}>
              <div>
                <b style={{textTransform:"uppercase"}}>Customer</b>
              </div>
              <div>
                <b>{clientName}</b>
              </div>
              <div>{newAddressArray?.[0]?.address}</div>
            </TableCell>
            <TableCell
              style={{
                width: "20%",
                borderLeft: "1px solid black",
                padding: 0,
              }}
            >
              <div style={{ borderBottom: "none", padding: "3px" }}>
                <b>Date of Receipt</b>
              </div>
              <div style={{ borderBottom: "none", padding: "3px" }}>
                <b>Service Request No.</b>
              </div>              
              <div style={{ borderBottom: "none", padding: "3px" }}>
                <b>Certificate No.</b>
              </div>
              <div style={{ borderBottom: "none", padding: "3px" }}>
                <b>Calibration Method</b>
              </div>              
              <div style={{ borderBottom: "none", padding: "3px" }}>
                <b>Calibration Field</b>
              </div>
            </TableCell>
            <TableCell
              style={{
                width: "30%",
                borderLeft: "none",
                padding: 0,
              }}
            >
              <div style={{ borderBottom: "none", padding: "3px" }}>
                : {formatDateByClient(receiptDate)}
              </div>
              <div style={{ borderBottom: "none", padding: "3px" }}>
                : {certificateServiceReqNumber || "\u00A0"}
              </div>              
              <div style={{ borderBottom: "none", padding: "3px" }}>
                : {certificateNumber || "\u00A0"}
              </div>
              <div style={{ padding: "3px" }}>
                : {calProcRefNo ? calProcRefNo : calibrationProcedureNo || "\u00A0"}
              </div>
              <div style={{ padding: "3px" }}>
                : {disciplineName || "\u00A0"}
              </div>
            </TableCell>
          </TableRow>
        </Table>
      );
    }
    function unitCalibrationDetails() {
      return (
        <Table
          className={classes.table}
          style={{ fontSize: "11px", marginBottom: "3px" }}
        >
          <TableRow className={classes.row}>
            <TableCell className={classes.infoCell} align="center">
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Name</b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>
                {requestedName.length > 0 ? requestedName : instrumentName}
              </TableCell>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Range</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {ranges}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>            
          <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>ID No.</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{DUCID}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Resolution</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                {lc
                    ?.replaceAll("||", ", ")
                    ?.replaceAll("|", " to ")
                    ?.replaceAll("#", " ")}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Make / Sr.No</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                {make && serialNo ? `${make} / ${serialNo}` : (make || serialNo)}
                </TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Acceptance Criteria</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                {accuracyFormat(accuracy)}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Department</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{departmet}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Location</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                {locationOfInstrument ? locationOfInstrument : ""}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
          {equipmentName ? (
              <TableCell className={classes.infoCell}>
                <TableRow style={{ border: "none", padding: "0px" }}>
                  <TableCell
                    style={{
                      border: "none",
                      width: "150px",
                      padding: "0px",
                    }}
                  >
                    <b>Equipment Name</b>
                  </TableCell>
                  <TableCell style={{ border: "none" }}>:</TableCell>
                  <TableCell style={{ border: "none" }}>{equipmentName}</TableCell>
                </TableRow>
              </TableCell>
            ) : null}
            {equipmentId ? (
              <TableCell className={classes.infoCell}>
                <TableRow style={{ border: "none", padding: "0px" }}>
                  <TableCell
                    style={{
                      border: "none",
                      width: "150px",
                      padding: "0px",
                    }}
                  >
                    <b>Equipment Id</b>
                  </TableCell>
                  <TableCell style={{ border: "none" }}>:</TableCell>
                  <TableCell style={{ border: "none" }}>{equipmentId}</TableCell>
                </TableRow>
              </TableCell>
            ) : null}
          </TableRow>
          <TableRow>
          {type ? (
              <TableCell className={classes.infoCell}>
                <TableRow style={{ border: "none", padding: "0px" }}>
                  <TableCell
                    style={{
                      border: "none",
                      width: "150px",
                      padding: "0px",
                    }}
                  >
                    <b>Type</b>
                  </TableCell>
                  <TableCell style={{ border: "none" }}>:</TableCell>
                  <TableCell style={{ border: "none" }}>{type}</TableCell>
                </TableRow>
              </TableCell>
            ) : null}
          </TableRow>
        </Table>
      );
    }
    function masterDataTable() {
      //Please don't put thead or tbody
      return (
        <div align="left">
          <center style={{marginTop:"-3px", marginBottom:"-3px"}}>
          <b style={{fontSize:"12px"}}>EQUIPMENT USED FOR CALIBRATION</b>
          </center>
          <small>Used standards are traceable to National/ International Standards through NPL/NABL Accredited Calibration Laboratory</small>
          <br></br>
          <table
            className="standardUnitTable single-border-table"
            align="center"
          >
            <tr>
              <td>
                <b>Description</b>
              </td>
              <td>
                <b>Id No.</b>
              </td>
              <td>
                <b>Calibration Agency No.</b>
              </td>
              <td>
                <b>Certificate No.</b>
              </td>
              <td>
                <b>Valid Up to</b>
              </td>
            </tr>
            {standardMasterArray.map((row, index) => (
              <tr key={row.name}>
                <td>{row.standardName}</td>
                <td>{row.stId}</td>
                <td>{row.traceability}</td>
                <td>{row.certificateNumber}</td>
                <td>{moment(row.validUpto).format("DD/MM/YYYY")}</td>
              </tr>
            ))}
          </table>
        </div>
      );
    }
    function opinionAndAnalysis() {
      return (
        <div>
          <div style={{ textAlign: "left" }}>
            <b>Opinion And Analysis</b>
          </div>
          <table
            className="standardUnitTable single-border-table"
            align="center"
          >
            <tr>
              <td>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <div>
                    <span>Accepted - Validate for use</span>
                  </div>
                  {checkboxOptions.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={
                          analysisSelectedOption?.accepted === (item === "✔")
                        }
                        value={item}
                      />
                      <label>{item}</label>
                    </div>
                  ))}
                </div>
              </td>
              <td>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <div>
                    <span>Calibrated</span>
                  </div>
                  {checkboxOptions.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={
                          analysisSelectedOption?.calibrated === (item === "✔")
                        }
                        value={item}
                      />
                      <label>{item}</label>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <div>
                    <span>Limited Use (Refer - Result)</span>
                  </div>
                  {checkboxOptions.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={
                          analysisSelectedOption?.limitedUse === (item === "✔")
                        }
                        value={item}
                      />
                      <label>{item}</label>
                    </div>
                  ))}
                </div>
              </td>
              <td>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <div>
                    <span>Rejected/Out of Use</span>
                  </div>
                  {checkboxOptions.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={
                          analysisSelectedOption?.rejected === (item === "✔")
                        }
                        value={item}
                      />
                      <label>{item}</label>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          </table>
        </div>
      );
    }
  };
  let nonAdminRoleType = userType != 1 && userType != 4;
    
  const showButton = (button) => {
    if (rejectedBy?.[0]) return false;
    const userTypeBollean = userType == 1 || userType == 4;
    if (!userTypeBollean && !roles_ids?.includes(button)) return false;
    let ret =
      calibratedby &&
      calibratedby.length > 0 &&
      calibratedby[0] !== user[1] &&
      !props.isPrint &&
      status !== -1;

    switch (button) {
      case 3:
        ret =
          ret &&
          approvedby?.[0] &&
          !reviewedBy[0] &&
          ((userTypeBollean &&
            (rolesSettins[3]?.name &&
              `${rolesSettins[3]?.name}`.toLocaleLowerCase() !== "false"
              )) ||
            props.rolesInfo?.currentRole?.id == 3);
        break;
      case 4:
        ret =
          ret &&
          approvedby?.[0] &&
          !signedBy[0] &&
          ((userTypeBollean &&
            (rolesSettins[4]?.name &&
              `${rolesSettins[4]?.name}`.toLocaleLowerCase() !== "false"
              )) ||
            props.rolesInfo?.currentRole?.id == 4);
        break;
      case 5:
        ret =
          ret &&
          !witnessedBy[0] &&
          ((userTypeBollean &&
            (rolesSettins[5]?.name &&
              `${rolesSettins[5]?.name}`.toLocaleLowerCase() !== "false"
              )) ||
            props.rolesInfo?.currentRole?.id == 5);
        break;
      case 1:
        ret =
          ret &&
          !approvedby?.[0] &&
        !reviewedBy[0] &&
        !signedBy[0] &&
        !witnessedBy[0] &&
          !rejectedBy[0] &&
          ((userTypeBollean &&
            (rolesSettins[1]?.name &&
              `${rolesSettins[1]?.name}`.toLocaleLowerCase() !== "false"
              )) ||
            props.rolesInfo?.currentRole?.id != 1);
        break;
      case 2:
        ret =
          ret &&
          !approvedby?.[0] &&
          ((userTypeBollean &&
            (rolesSettins[2]?.name &&
              `${rolesSettins[2]?.name}`.toLocaleLowerCase() !== "false"
              )) ||
            props.rolesInfo?.currentRole?.id == 2);
        break;
      default:
        ret = false;
        break;
    }

    return ret;
  };

  const renderButtonPair = (button, label, color, status, nonAdminRoleType) => (
    <>
      <Button
        id={`certificate-certificateview_${label.toLowerCase()}btn`}
        variant="contained"
        color={color}
        size="small"
        sx={{ ml: 3 }}
        onClick={() => {
          if (label == "Approve" && settingList["Compliance Status Alert"]?.toLowerCase() == "true" && complianceStatus != 1) {
            window.alert("Compliance Status is failed.");
          } else {
           
            ChangeCertificateStatus(status, [id], null,null,null,null,null,null,settingList,allocatedULRNo,calType);
          }
          if(editAccess?.includes(7) && label == "Approve" && settingList["Compliance Status Alert"]?.toLowerCase() == "true" && complianceStatus != 1){
            ChangeCertificateStatus(status, [id], null,null,null,null,null,null,settingList,allocatedULRNo,calType);
          }
        }}
      >
        {label}
      </Button>
      {nonAdminRoleType && ( <Button
        id="certificate-certificateview_Rejectbtn"
        variant="contained"
        color="error"
        size="small"
        sx={{ ml: 3 }}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Reject
      </Button>
      )}
    </>
  );

  const renderAdminRejectButton = () => (
    (showButton(3) || showButton(4) || showButton(5) || showButton(2)) && (
      <Button
        id="certificate-certificateview_Rejectbtn"
        variant="contained"
        color="error"
        size="small"
        sx={{ ml: 3 }}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Reject
      </Button>
    )
  );

  const ShowRejectModal = () => {
    const [rejectReason, setRejectReason] = useState("");
    return (
      <Modal
        open={modalOpen}
        onClose={(e, r) => {
            handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={modalStyle}>
          <TextField
            id="outlined-basic"
            label="Reason For Rejection"
            value={rejectReason}
            multiline
            maxRows={5}
            rows={5}
            size="small"
            fullWidth
            variant="outlined"
            style={{ marginBottom: "5px" }}
            onChange={(e) => setRejectReason(e.target.value)}
          />
          <Tooltip title="Send Rejection Reason" placement="top-start">
            <Button
              variant="contained"
              color="error"
              size="small"
              sx={{ m: 2 }}
              onClick={() => {
                ChangeCertificateStatus("reject",[id],calibratedby,instrumentName,DUCID, rejectReason,props.rolesInfo?.currentRole?.name,approvedby);
              }}
              disabled={rejectReason === ""}
            >
              Reject
            </Button>
          </Tooltip>
        </Box>
      </Modal>
    );
  };

  return (
    <>
      {!props.isPrint && !props.certificateIds && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title="User Guide" placement="top-start">
            <Button
              onClick={() => {
                viewCertificateGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </div>
      )}

      {paramCertificateId && (
        <Toolbar style={{ padding: "0px", width: "95%", margin: "auto" }}>
          {userType == 3 ? (
            // Show content for userType 3
            <Grid container spacing={2}>
              {/* Elements for userType 3 */}

              <Grid item xs={12} textAlign={"right"}>
                <Button
                  id="certificate-certificateviewDownalod"
                  variant="contained"
                  size="small"
                  disabled={!(editAccess?.includes(3) || editAccess?.includes(0))}
                  // sx={{ ml: 3 }}
                  onClick={() => {
                    if(paramMultipleCertificateIdsArray.length > 1) {                      
                      setpdfGenerated(true);
                    }else{
                     generatePDF(printProps);
                    downloadAuditlog();
                    }
                  }}
                >
                  {approvedby ? "Download PDF" : "Download Draft"}
                </Button>
              </Grid>
            </Grid>
          ) : // For other userTypes
          ((userType === 1 || userType === 4) &&
              approvedby &&
              !props.certificateIds) ||
            (!props.isPrint && approvedby && !props.certificateIds) ? (
            <Grid container spacing={2} align="left">
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  disabled={draft}
                  size="small"
                  id="certificate-certificateviewletterhead"
                  options={[
                    { id: 1, label: "With Letter Head" },
                    { id: 2, label: "Without Letter Head" },
                  ]}
                  renderInput={(params) => (
                    <TextField {...params} label="letterHead" />
                  )}
                  onInputChange={(event, newInputValue) => {
                    if (
                      newInputValue === "With Letter Head" ||
                      newInputValue === ""
                    ) {
                      setLetterHead(true);
                      setDraft(false);
                    } else if (newInputValue === "Without Letter Head") {
                      setLetterHead(false);
                    }
                  }}
                />
              </Grid>
              {settingList["letterHeadCompanyType"] && settingList["letterHeadCompanyType"]?.toLowerCase() === "true" && letterHead && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  size="small"
                  options={[
                    { id: 1, label: "Old" },
                    { id: 2, label: "New" },
                  ]}
                  renderInput={(params) => (
                    <TextField {...params} label="Select LetterHead Type" />
                  )}
                  onInputChange={(event, newInputValue) => {
                    if (
                      newInputValue === "Old" ||
                      newInputValue === ""
                    ) {
                      setLetterHeadCompnayType(true);
                    } else if (newInputValue === "New") {
                      setLetterHeadCompnayType(false);
                    }
                  }}
                />
              </Grid>
              )}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  size="small"
                  id="certificate-certificateviewelectronicsign"
                  options={[
                    { id: 1, label: "With Electronically Signed" },
                    { id: 3, label: "Signature Image" },
                    { id: 2, label: "Without  Electronically Signed" },
                  ]}
                  renderInput={(params) => (
                    <TextField {...params} label="Electronically Signed" />
                  )}
                  onInputChange={(event, newInputValue) => {
                    if (
                      newInputValue === "With Electronically Signed" ||
                      newInputValue === ""
                    ) {
                      setElectronicSign(true);
                      setSignImg(false);
                    } else if (
                      newInputValue === "Without  Electronically Signed"
                    ) {
                      setElectronicSign(false);
                      setSignImg(false);
                      }
                      else if (
                        newInputValue === "Signature Image"
                      ) {
                      setElectronicSign(false);
                      setSignImg(true);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Autocomplete
                  size="small"
                  options={
                    letterHead ?
                    [{ id: 1, label: "Scan Copy" },
                    { id: 2, label: "Without Scan Copy" }]
                    :
                    [{ id: 1, label: "With Draft" },
                    { id: 2, label: "Without Draft" }]
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Draft " />
                  )}
                  onInputChange={(event, newInputValue) => {
                    if (
                      newInputValue === "With Draft" ||
                      newInputValue === ""
                    ) {
                      setDraft(true);
                      setLetterHead(false);
                      setScanCopy(false);
                    } else if (newInputValue === "Without Draft") {
                      setDraft(false);
                      setScanCopy(false);
                      // setLetterHead(true)
                    } else if (newInputValue === "Scan Copy") {
                      setDraft(false);
                      setScanCopy(true);
                    }  else if (newInputValue === "Without Scan Copy") {
                      setScanCopy(false);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={2} lg={2}>
                <Button
                  id="certificate-certificateviewDownalod"
                  variant="contained"
                  size="small"
                  disabled={!(editAccess?.includes(3) || editAccess?.includes(0))}
                  onClick={() => {
                    if(paramMultipleCertificateIdsArray.length > 1) {                      
                      setpdfGenerated(true);
                    }else{
                    generatePDF(printProps);
                    downloadAuditlog();
                    }
                  }}
                >
                  Download PDF
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Toolbar>
      )}
      {props.isPrint === true && approvedby && (
        <Grid item xs={2}>
          <Button
            id="certificate-certificateviewDownalod"
            variant="contained"
            size="small"
            // disabled={!(editAccess?.includes(3) || editAccess?.includes(0))}
            onClick={() => {
              if (storedDeviceInfo === "mobile" || storedDeviceInfo === "tablet") {
                alert("To download PDF, please use the desktop site.");
              } else {
                if(paramMultipleCertificateIdsArray.length > 1) {                  
                  setpdfGenerated(true);
                }else{
                generatePDF(printProps);
                }
              }
            }}
          >
            Download PDF
          </Button>
        </Grid>
      )}

      <div style={{width: props.isPrint === true ? "85%" : "100%",margin:"auto"}}>
      {printView()}
      </div>

      <Toolbar style={{ padding: "0px", width: "100%" }}>
      {showButton(3) && renderButtonPair(3, 'Review', 'success', 'review',nonAdminRoleType)}
      {showButton(4) && renderButtonPair(4, 'Sign', 'error', 'sign',nonAdminRoleType)}
      {showButton(5) && renderButtonPair(5, 'Witnessed', 'info', 'witness',nonAdminRoleType)}
      {showButton(2) && renderButtonPair(2, 'Approve', 'success', 'approve',nonAdminRoleType)}
      {(userType == 1 || userType == 4) ? renderAdminRejectButton() : showButton(1)}

      
      </Toolbar>
      {ShowRejectModal()}
      
      {downloadPdfloading && (
        <DownloadPdfLoaderModal
          loading={downloadPdfloading}
          name={"Certificate"}
          abort={true} 
        />
      )}
      {errormodalIsOpen && (
        <ErrorModal
          errormodalIsOpen={errormodalIsOpen}
          closeErrorModal={closeErrorModal}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
}
